import React from 'react'
import styled, { ThemeProvider } from 'styled-components'

import themes from '../../styles/themes'
import GlobalStyle from './GlobalStyle'
import BackgroundImage from './BackgroundImage'
import { DEFAULT_BREAK_POINT } from '../../styles/base'

interface Props {
  fluidImageData?: string
}

export const SplitLayout: React.FC<Props> = ({ children, fluidImageData }) => (
  <ThemeProvider theme={themes.base}>
    <GlobalStyle />
    <Grid>
      <Left>
        <BackgroundImage fluidImageData={fluidImageData} />
      </Left>
      <Right>{children}</Right>
    </Grid>
  </ThemeProvider>
)

const Grid = styled.div`
  display: grid;
  grid-column-gap: 0;
  grid-row-gap: 0;

  grid-template-columns: 1fr;
  //grid-template-rows: repeat(2, 1fr);

  @media ${DEFAULT_BREAK_POINT} {
    grid-template-rows: 1fr;
    grid-template-columns: repeat(2, 1fr);
    height: 100vh;
  }
`

const Left = styled.div``

const Right = styled.div``
