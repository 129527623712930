import React from 'react'
import { graphql, PageProps, Link, useStaticQuery } from 'gatsby'
import styled from 'styled-components'

import { SplitLayout } from '../components/layout/SplitLayout'
import { Logo as Logo_ } from '../components/layout/Logo'
import { SEO } from '../components/SEO'
import { DEFAULT_BREAK_POINT } from '../styles/base'

const FrontPage: React.FC<PageProps> = () => {
  const data = useStaticQuery(
    graphql`
      query {
        bgImage: file(absolutePath: { regex: "/assets/backgrounds/gereedschap-goudsmid-lof.jpg/" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  )

  return (
    <SplitLayout fluidImageData={data.bgImage.childImageSharp.fluid}>
      <SEO title="Atelier Lof" />
      <Grid>
        <Row>
          <TopContent>
            <Link to="/">
              <LogoWrapper>
                <Logo />
              </LogoWrapper>
            </Link>

            <p>
              Vanuit hartje Leiden ontwerpen wij originele sieraden. Stuur ons een <a href="mailto:info@atelierlof.nl">mailtje</a> om te
              horen wat de mogelijkheden zijn.
            </p>
          </TopContent>
        </Row>
        <Row>
          <BottomContent>
            <BottomFlex>
              <BottomLeft>
                <Line>Paardentramhof 3</Line>
                <Line>2311 ZV Leiden</Line>
                <Line>071-3040100</Line>
                <Line>
                  <a href="mailto:info@atelierlof.nl">info@atelierlof.nl</a>
                </Line>
                <Line>&nbsp;</Line>
              </BottomLeft>
              <BottomRight>
                <Line>KvK Den Haag nr. 55225349</Line>
                <Line>BTW nr. NL851615302B01</Line>
                <Line>IBAN: NL10INGB0004349611</Line>
              </BottomRight>
            </BottomFlex>
          </BottomContent>
        </Row>
      </Grid>
    </SplitLayout>
  )
}

export default FrontPage

const Grid = styled.div`
  display: grid;
  @media ${DEFAULT_BREAK_POINT} {
    height: 100%;
  }
`

const Row = styled.div`
  display: flex;
  align-items: center;
  align-content: flex-end;
`

const TopContent = styled.div`
  margin: 0 auto;
  padding: 20px;
  align-self: flex-end;
  @media ${DEFAULT_BREAK_POINT} {
    width: 60%;
    max-width: 600px;
  }
`

const BottomContent = styled.div`
  flex: 1;
  align-self: flex-end;
  margin: 0 auto;
  padding: 20px;
  @media ${DEFAULT_BREAK_POINT} {
    max-width: 90%;
  }
`

const LogoWrapper = styled.div`
  text-align: center;
  margin-top: 30px;
  margin-bottom: 40px;
`

const Logo = styled(Logo_)`
  color: ${({ theme }) => theme.colors.logo};
  height: 100px;
  @media ${DEFAULT_BREAK_POINT} {
    height: 150px;
  }
  width: auto;
`

const BottomFlex = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  column-gap: 20px;
  flex-flow: wrap;
`

const BottomLeft = styled.div``

const BottomRight = styled.div``

const Line = styled.div`
  //font-weight: bold;
  font-size: 16px;
  text-transform: uppercase;
  white-space: nowrap;
  @media ${DEFAULT_BREAK_POINT} {
    font-size: unset;
  }
`
