import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import styled from 'styled-components'

import { DEFAULT_BREAK_POINT } from '../../styles/base'

interface Props {
  className?: string
  fluidImageData?: string
}

const BackgroundComponent: React.FC<Props> = ({ className, fluidImageData }) => {
  const data = useStaticQuery(
    graphql`
      query {
        bgImage: file(absolutePath: { regex: "/assets/backgrounds/gereedschap-goudsmid-lof.jpg/" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  )

  const imageData = fluidImageData ?? data.bgImage.childImageSharp.fluid

  return <BackgroundImage Tag="div" className={className} fluid={imageData} />
}

const StyledBackgroundComponent = styled(BackgroundComponent)`
  height: 50vh;
  @media ${DEFAULT_BREAK_POINT} {
    height: 100vh;
  }
  min-width: 100%;
  background-size: cover;
`

export default StyledBackgroundComponent
